
<template>
    <div class="list">
		
			
		
		<el-row :gutter="20">
			<el-col :span="2">
				<div class="grid-content bg-purple">
					<el-row style="margin-bottom: 10px;">
						<el-button size="mini" plain @click="forArr(data,true)">全部展开</el-button>
					</el-row>
					 <el-row>
						<el-button size="mini" plain @click="forArr(data,false)">全部折叠</el-button>
					</el-row>
					<el-row>
						<el-button size="mini" type="primary"  icon="el-icon-plus" @click="add(0)">添加</el-button>
					</el-row>
				</div>
			</el-col>
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true" :model="formInline" class="demo-form-inline">
							<el-form-item label="菜单名称">
								<el-input v-model="formInline.title" placeholder="菜单名称"></el-input>
							</el-form-item>
							<el-form-item label="状态">
								<el-select v-model="formInline.status" placeholder="状态">
								<el-option label="开启" value="1"></el-option>
								<el-option label="关闭" value="0"></el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item>
								<el-button type="primary" @click="onSubmit">查询</el-button>
								<el-button @click="reset">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-table  v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" ref ="treeTable" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column align="center" prop="id" label="序号" sortable width="80" type=""></el-table-column>
			<el-table-column align="center" prop="name" label="菜单名称" width="260"></el-table-column>
			
			<el-table-column align="center" width="100"  label="状态">
				<template slot-scope="scope" >
					<el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"></el-switch>
				</template>
			</el-table-column>
			
			<el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="260">
      			<template slot-scope="scope">
					<el-button  @click="modify(scope.row)" type="text" size="small"><i class="el-icon-edit"></i>编辑</el-button>
					&nbsp;
					<el-popconfirm title="确定要删除这条数据吗" @confirm="deletes(scope.row.id,data)">
						<el-button class="text-danger"  slot="reference"  type="text" size="small"><i class="el-icon-delete"></i>删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>

		<Modal v-model="create" @on-cancel="cancelModal" draggable sticky scrollable :mask="false" title="添加行业">
			<create :pid="pid" :key="timer" v-on:fun="createForm"></create>
		</Modal>
		<Modal v-model="edit" @on-cancel="cancelModal" draggable sticky scrollable :mask="false" title="编辑行业">
			<edit :id="id"  v-on:fun="editForm"></edit>
		</Modal>
	</div>
	
</template>
<script>
	import {request} from "@/library/request.js";
	import create from "components/system/industry/create.vue"
	import edit from "components/system/industry/edit.vue"
    export default {
		components:{
			create,
			edit
		},
        data () {
            return {
				timer:"",
				data:[],
				total:0,
				pageSize:15,
				currentPage:0,
				loading: false,
				create: false,
				edit:false,
				formInline: {
					title: '',
					status:'',
				},
				pid:0,
				id:0
			}
        },
		methods: {

			changeSwitch(data){
				this.$Loading.start();
				
				let param = new URLSearchParams()
				param.append('status', data.status)
				return request({
					url:'system/industry/status/'+data.id,
					method:"post",
					data:param
				}).then(response  => {
					this.$Loading.finish();
					// this.loading = false
					if(response.code!=200){
						this.$Message.error(response.msg)
					}
				}).then(err => {
					if(err){
						// this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
			},
			forArr(arr,isExpand) {
				arr.forEach(i => {	
					this.$refs.treeTable.toggleRowExpansion(i, isExpand);
					if (i.children) {
						this.forArr(i.children, isExpand);
					}
				});
			},
			onSubmit(){
				this.getList()
			},
			getList(){
				this.$Loading.start();
				this.loading = true
				return request({
					url:'system/industry/list',
					method:"get",
					params:{searchParams:{'name-%%':this.formInline.title,'status-=':this.formInline.status,'is_menu-=':this.formInline.is_menu}}
				}).then(response  => {
					this.$Loading.finish();
					this.loading = false
					this.data = response.data.data
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			reset(){
				this.formInline.title=""
				this.formInline.status=""
				this.getList()
			},
			add(value){
				if(value||value==0){
					
					this.pid = value
				}
				this.create = true
			},
			createForm(res){
				this.create=res
				this.getList();
				this.timer = new Date().getTime()
			},
			editForm(res){
				this.edit=res
				this.getList();
			},
			cancelModal(){
				this.pid=null
				this.timer = new Date().getTime()
			},
			deletes(value,row){
				var that = this
				if(value){
					this.$Loading.start();
					this.loading = true
					return request({
						url:'system/industry/delete/'+value,
						method:"post",
					}).then(response  => {
						if(response.code==200){
							this.remove(value,row)
						}
						this.$Loading.finish();
						this.loading = false
						
					}).then(err => {
						if(err){
							this.loading = false
							this.$Loading.error();
							this.$Message.error("系统异常，请稍后重试")
						}
					})
				}
			},
			remove(value,row){
				var index = null;
				for(var i=0; i<row.length; i++){
					if(row[i].id!=value){
						if(row[i].children){
							this.remove(value,row[i].children)
						}
					}else{
						 row.splice(i, 1);
					}
				}
			},
			modify(row){
				this.id = row.id
				this.edit = true
			},
			prev(value){
				this.currentPage = value
				this.getList()
			},
			next(value){
				this.currentPage = value
				this.getList()
			},
			CurrentChange(value){
				this.currentPage = value
				this.getList()
			},
		},
		mounted() {

			this.getList()
		}
    }
</script>
<style scoped>
	@import url('/css/app.css');
</style>