<template>
	<div>
		<el-form :model="formData"  v-loading="loading"  :rules="rules" ref="ruleForm" status-icon label-width="100px">


			<el-form-item label="行业名称" prop="name">
				<el-input type="text" placeholder="请输入行业名称" v-model="formData.name" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>
		<div class="drawer-footer">
			<Button style="margin-right: 8px">重置</Button>
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
import { handleError } from "@vue/runtime-core";
	
export default {
	props:['id'],
	data(){
		return {
			formData:{
				name:"",
			},
			loading:true,
			pidData:[],
			rules:{
				name:[
					{required: true, message: '请输入行业名称'},
					{max:40, message: '长度在40个字符', trigger: 'blur' }
				],
			}
		}
	},
	methods:{
		onSubmit(formName){
			console.log(this.formData.address)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
			var that = this
			return request({
				url:'system/industry/edit/'+this.id,
				method:"post",
				params:this.formData
			}).then(response  => {
				if(response.code==200){
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.$emit('fun', false)
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
	},
	mounted(){
	},
	watch:{
		id(newValue,oldValue){
			
			return request({
				url:'system/industry/edit/'+newValue,
				method:"get",
			}).then(response  => {
				this.loading=false
				if(response.code!=200){
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}else{

					this.formData = response.data
				}
				
			}).then(err => {
				if(err){
					this.loading=false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		}
	}
	

}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
	/*设置级联选择器选中后关闭下拉start*/
	.el-cascader__dropdown .el-cascader-panel .el-radio {
	    width: 100%;
	    height: 100%;
	    z-index: 10;
	    position: absolute;
	    right: 0;
	}
	.el-cascader__dropdown .el-cascader-panel .is-disabled {
	  	cursor: not-allowed;
	}
	.el-cascader__dropdown .el-cascader-panel .el-radio__input {
	   visibility: hidden;
	}
	/*设置级联选择器选中后关闭下拉end*/
</style>